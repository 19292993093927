import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const Provider = ({ category, length, children }) => {
  return (
    <Context.Provider value={{ category, length }}>{children}</Context.Provider>
  )
}
