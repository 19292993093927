import React from 'react'
import { Initializer } from './Initializer'
import * as Consts from 'modules/page/design/consts'

/**
 *
 */
export const PostOwnedByCompanyInitializer = ({ children }) => {
  const responsiveData = [
    {
      threshold: 760,
      data: {
        narrow: { name: Consts.DESIGN_TYPE_NAME.MOBILE, height: 700 },
        wide: { name: Consts.DESIGN_TYPE_NAME.LAPTOP, height: 290 },
      },
    },
  ]

  return <Initializer value={{ responsiveData }}>{children}</Initializer>
}
