import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

/**
 * Header Wave
 */
export const Header = () => (
  <StaticImage
    src="../../../static/img/UpWave.png"
    className={styles.Header}
    layout="fullWidth"
    placeholder="none"
    alt="UpperWave"
    quality="100"
  />
)
