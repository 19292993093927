import React, { useContext } from 'react'
import { Context } from './Context'

/**
 *
 */
export const Provider = ({ value, children }) => {
  return <Context.Provider value={{ ...value }}>{children}</Context.Provider>
}

/**
 */
export const useWindowWidth = () => {
  return useContext(Context)
}
