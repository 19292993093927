import React from 'react'
import { Logo } from 'components/Logo'

import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Header = () => {
  return (
    <div className={styles.Header}>
      <Logo />
    </div>
  )
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
  </Container>
)
