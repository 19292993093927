import React from 'react'
import { Helmet } from 'react-helmet'
import * as Image from 'modules/image/components'
import 'styles/wordpress.scss'

/**
 *
 */
export const Layout = ({ children, meta }) => {
  return (
    <>
      <Helmet>
        <script id="_bownow_ts">
          var _bownow_ts = document.createElement('script'); _bownow_ts.charset
          = 'utf-8'; _bownow_ts.src =
          'https://contents.bownow.jp/js/UTC_68e43fffdfda4406010d/trace.js';
          document.getElementsByTagName('head')[0].appendChild(_bownow_ts);
        </script>
        <link
          rel="stylesheet"
          href={`${process.env.GATSBY_SITE_HOSTNAME}/css/wp-includes/css/dist/block-library/style.min.css`}
        />
        <link
          rel="stylesheet"
          href={`${process.env.GATSBY_SITE_HOSTNAME}/css/wp-includes/css/dist/block-library/theme.min.css`}
        />
        <link
          rel="stylesheet"
          href={`${process.env.GATSBY_SITE_HOSTNAME}/css/wp-content/themes/archmateriav1/style.css`}
        />
        <link
          rel="stylesheet"
          href={`${process.env.GATSBY_SITE_HOSTNAME}/css/wp-content/themes/archmateriav1/assets/css/print.css`}
        />

        {meta?.title && <title>{meta?.title}</title>}
        {meta?.description && (
          <meta name="description" content={meta?.description} />
        )}

        <meta name="非表示のページです" content="noindex" />
      </Helmet>
      <Image.Initializer>{children}</Image.Initializer>
    </>
  )
}
