import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const Provider = ({ children, value }) => {
  return (
    <Context.Provider value={{ allMedia: value.allMedia }}>
      {children}
    </Context.Provider>
  )
}
