import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Provider } from './Provider'

/**
 *
 */
export const Initializer = ({ children }) => {
  const { allMedia } = useStaticQuery(query)
  return <Provider value={{ allMedia: allMedia.nodes }}>{children}</Provider>
}

/**
 *
 */
const query = graphql`
  {
    allMedia: allWordpressWpMedia {
      nodes {
        source_url
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO)
          }
        }
      }
    }
  }
`
