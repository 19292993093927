import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const Title = () => {
  const { title } = React.useContext(Context)
  return title ? <h1>{title}</h1> : null
}

/**
 *
 */
export const Text = () => {
  const { text } = React.useContext(Context)
  return text ? <p>{text}</p> : null
}
