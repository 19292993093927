/**
 *
 */
export const getResponsiveHeight = (
  responsiveData,
  name,
  defaultHeight = 100
) => {
  const findData = responsiveData.find(
    (el) => el.data.narrow.name === name || el.data.wide.name === name
  )

  if (!findData) {
    return defaultHeight
  }

  return findData.data.narrow.name === name
    ? findData.data.narrow.height
    : findData.data.wide.height
}
