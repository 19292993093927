import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const withCompany = (Component) => (props) => {
  const { company } = React.useContext(Context)
  return <Component company={company} {...props} />
}
