import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const Provider = ({ product, length, children }) => {
  return (
    <Context.Provider value={{ product, length }}>{children}</Context.Provider>
  )
}
