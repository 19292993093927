import React from 'react'
import parse from 'html-react-parser'

/**
 *
 */
export const parser = ({ content }) => {
  return parse(content, { replace })
}

/**
 *
 */
const replace = (node) => {
  if (
    (node.name === 'p' || node.name === 'a') &&
    (node.attribs.class === 'link-more' || node.attribs.class === 'more-link')
  ) {
    return <></>
  }
}
