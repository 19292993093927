import React from 'react'

import * as styles from './styles.module.scss'
import * as Links from 'components/shared/Links'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/*
 *
 */
const Footer = () => (
  <div>
    <ul className={styles.Menu}>
      <li className={styles.Menu_Item}>
        <Links.ToCompaniesPage className={styles.Link} target="_blank">
          掲載企業一覧
        </Links.ToCompaniesPage>
      </li>
      <li className={styles.Menu_Item}>
        <a
          className={styles.Link}
          target="_blank"
          href="https://m-arch-log.com/#Anchor02"
        >
          {' '}
          会社概要
        </a>
      </li>
      <li className={styles.Menu_Item}>
        <a
          className={styles.Link}
          href="https://www.arch-log.com/ja/terms/"
          target="_blank"
        >
          利用規約
        </a>
      </li>
      <li className={styles.Menu_Item}>
        <a
          className={styles.Link}
          href={process.env.MATERIA_GATSBY_APP_MATERIA_DOCUMENT_REQUEST}
          target="_blank"
        >
          広告掲載
        </a>
      </li>
      <li className={styles.Menu_Item_Policy}>
        <a
          className={styles.Link}
          href="https://www.arch-log.com/ja/privacyPolicy/"
          target="_blank"
        >
          プライバシーポリシー
        </a>
      </li>
    </ul>
    <p className={styles.Copyright}>
      Copyright ©2022 LOGLOG CO.,Ltd. All Rights Reserved. Licensed to Marubeni
      Arch-LOG Co.,Ltd.
    </p>
  </div>
)

/**
 */
export const Content = () => (
  <Container>
    <Footer />
  </Container>
)
