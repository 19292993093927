import React from 'react'
import { Context } from 'templates/CampaignOverviewTemplate'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import * as RelatedPostsBase from './RelatedPosts'
import * as Link from 'components/shared/Links.jsx'
import * as styles from './styles.module.scss'
import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 *
 */
export const Title = ({ components: Decorator = Styled.Title }) => {
  const { data } = React.useContext(Context)
  return (
    <Decorator>
      <Literals.Title title={data.wordpressPost.title} />
    </Decorator>
  )
}

export const Hero = () => {
  const { data } = React.useContext(Context)
  const imageSrc =
    data.wordpressPost.featured_media.localFile.childImageSharp.gatsbyImageData
  return <MyImage largeImage={imageSrc} smallImage={imageSrc} />
}

export function MyImage({ largeImage, smallImage }) {
  const images = withArtDirection(getImage(largeImage), [
    {
      media: '(max-width: 1024px)',
      image: getImage(smallImage),
    },
  ])

  return <GatsbyImage className={styles.ArtDirected} image={images} />
}

/**
 *
 */
export const PostContent = ({ className }) => {
  const { data } = React.useContext(Context)
  return (
    <div
      dangerouslySetInnerHTML={{ __html: `${data.wordpressPost.content}` }}
      className={className ?? 'entry-content'}
    ></div>
  )
}

/**
 *
 */
export const RelatedPosts = () => {
  return <RelatedPostsBase.Default />
}

/**
 *
 */
export const ToTop = ({ components: Decorator = Styled.Button }) => {
  return (
    <Decorator className={`${styles.Button} ${styles.Button_ToTop}`}>
      <Link.ToTopPage className={styles.Link} />
    </Decorator>
  )
}

/**
 *
 */
export const ToCategory = ({ components: Decorator = Styled.Button }) => {
  return (
    <Decorator className={`${styles.Button} ${styles.Button_ToCategory}`}>
      <Link.ToCategoriesPage className={styles.Link} />
    </Decorator>
  )
}

/**
 *
 */
export const ToCampaigns = ({ components: Decorator = Styled.Button }) => {
  return (
    <Decorator className={`${styles.Button} ${styles.Button_ToCampaign}`}>
      <Link.ToCampaignsPage className={styles.Link} />
    </Decorator>
  )
}

/**
 *
 */
export const ToCampaignChildren = ({
  components: Decorator = Styled.Button,
}) => {
  return (
    <Decorator
      className={`${styles.Button} ${styles.Button_ToCampaignChildren}`}
    >
      <Link.ToCampaignChildrenPage className={styles.Link} />
    </Decorator>
  )
}
