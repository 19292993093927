import React from 'react'
import * as styles from './styles.module.scss'
import { Content as Footer } from 'components/shared/Footer'
import { Content as HeaderContent } from './Header'
import { BottomWavedContainer as BodyWrapper } from 'components/WavedContainer'
import { Helmet } from 'react-helmet'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Header = () => {
  return (
    <div className={styles.Header}>
      <HeaderContent />
    </div>
  )
}

/**
 */
export const Body = ({ children }) => (
  <BodyWrapper>
    <div className={styles.Body}>{children}</div>
  </BodyWrapper>
)

/**
 */
export const Layout = ({ children, metaTitle }) => (
  <Container>
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="非表示のページです" content="noindex" />
    </Helmet>
    <Header />
    <Body>{children}</Body>
    <Footer />
  </Container>
)
