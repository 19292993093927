import React from 'react'
import * as Layouts from './Layouts'
import { Logo } from 'components/Logo'
import { Content as FooterItem } from 'components/shared/Footer'
import { BottomWavedContainer } from '../../components/WavedContainer/Container'
import { Helmet } from 'react-helmet'

/**
 *
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layouts.Wrapper,
      Header = Layouts.Header,
      Body = Layouts.Body,
      Footer = Layouts.Footer,
    } = {},
  } = {},
  children,
}) => {
  return (
    <Wrapper>
      <Helmet>
        <meta name="非表示のページです" content="noindex" />
      </Helmet>
      <Header>
        <Logo />
      </Header>
      <Body>{children}</Body>
      <BottomWavedContainer />
      <Footer>
        <FooterItem />
      </Footer>
    </Wrapper>
  )
}
