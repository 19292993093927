import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

/**
 * Footer Wave
 */
export const Footer = () => (
  <StaticImage
    src="../../../static/img/BottomBanner.png"
    className={styles.Footer}
    placeholder="none"
    backgroundColor="transparent"
    layout="fullWidth"
    alt="LowerWave"
    quality="100"
  />
)
