import React from 'react'

/**
 */
export const Link = ({ children, className, href, ...props }) => {
  return (
    <a className={className} href={href} {...props}>
      {children}
    </a>
  )
}
