import React from 'react'
import { Helmet } from 'react-helmet'

/**
 *
 */
export const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta name="非表示のページです" content="noindex" />
      </Helmet>
      <div>{children}</div>
    </>
  )
}

export default Layout
