import { parser } from './parser'

/**
 *
 */
export const HTMLRenderer = ({ value }) => {
  if (value.length >= 75) {
    const sliceExcept = value.slice(0, 75) + '...'
    const parseHTML = parser({ content: sliceExcept })
    return parseHTML
  }
  const parseHTML = parser({ content: value })
  return parseHTML
}
