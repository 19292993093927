import React from 'react'
import { withCompany } from '../withCompany'
import { DefaultHTMLRenderer } from 'components/shared/Renderer/Content'
import { DefaultTextRenderer as TextRenderer } from 'components/shared/Renderer'

/**
 *
 */
export const Title = withCompany(
  ({ company, render: Renderer = TextRenderer, ...props }) => {
    return <Renderer value={company.entry.title} {...props} />
  }
)

/**
 *
 */
export const Content = withCompany(
  ({ company, render: Renderer = DefaultHTMLRenderer, ...props }) => {
    if (company === undefined) {
      return null
    }

    return <Renderer value={company.content} {...props} />
  }
)
