import React from 'react'
import { withCategory } from '../withCategory'
import { DefaultTextRenderer as TextRenderer } from 'components/shared/Renderer'
/**
 *
 */
export const Name = withCategory(
  ({ category, render: Renderer = TextRenderer, ...props }) => {
    return <Renderer value={category.name} {...props}></Renderer>
  }
)

export const Description = withCategory(
  ({ category, render: Renderer = TextRenderer, ...props }) => {
    return <Renderer value={category.description} {...props}></Renderer>
  }
)
