import React from 'react'
import 'styles/global.scss'
import { Layout as DefaultPageLayout } from '../DefaultPageLayout'
import { Layout as TopPageLayout } from '../TopPageLayout'
import { Layout as CategoryPageLayout } from '../CategoryPageLayout'
import { Layout as HidePageLayout } from '../HIdePageLayout'
import * as Campaign from '../CampaignPageLayout'

import { Initializer } from 'modules/page/width/components/Reference/Initializer'

/**
 */
export const Container = ({ children, ...props }) => {
  const path = props?.location?.pathname ?? ''
  const metaTitle = props?.pageContext?.meta?.title ?? ''

  const Component = () => {
    if (path === '/') {
      return <TopPageLayout metaTitle={metaTitle}>{children}</TopPageLayout>
    } else if (path.match('/categories/')) {
      return (
        <CategoryPageLayout metaTitle={metaTitle}>
          {children}
        </CategoryPageLayout>
      )
    } else if (path === '/companies') {
      return (
        <DefaultPageLayout metaTitle={metaTitle} pageTitle="掲載企業一覧">
          {children}
        </DefaultPageLayout>
      )
    } else if (path.match('/product-lists/')) {
      return <HidePageLayout>{children}</HidePageLayout>
    } else if (path.match('/arch-log-products/')) {
      return <HidePageLayout>{children}</HidePageLayout>
    } else if (path.match('/all-product-lists')) {
      return <HidePageLayout>{children}</HidePageLayout>
    } else if (path.match('/campaign/sdgs')) {
      return <Campaign.Default>{children}</Campaign.Default>
    } else if (path.match('/campaign')) {
      return <Campaign.Default>{children}</Campaign.Default>
    }
    return (
      <DefaultPageLayout metaTitle={metaTitle}>{children}</DefaultPageLayout>
    )
  }

  return (
    <Initializer>
      <Component />
    </Initializer>
  )
}

/**
 */
export default Container
