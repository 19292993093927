import React, { useRef, useLayoutEffect, useMemo } from 'react'
import moment from 'moment'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

/**
 *
 */
export const DefaultTextRenderer = ({ value, className }) => (
  <p className={className}>{value}</p>
)

/**
 *
 */
export const DefaultTitleRenderer = ({ value, className }) => {
  return <h1 className={className}>{value}</h1>
}

/**
 *
 */
export const DefaultHTMLRenderer = ({ value, className }) => {
  const divRef = useRef()

  const updatedValue = value
    .replace(/.png/g, '.webp')
    .replace(/.jpg/g, '.webp')
    .replace(/.jpeg/g, '.webp')

  const initialHTMLString = useMemo(() => {
    const scriptStrings = updatedValue.match(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    )
    if (scriptStrings) {
      return scriptStrings.reduce((acc, current) => {
        return acc.replace(current, '')
      }, updatedValue)
    }
    return null
  }, [updatedValue])

  useLayoutEffect(() => {
    if (!divRef.current) {
      return
    }

    ;(async () => {
      const scriptStrings = updatedValue.match(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
      )

      let updatedHtmlString = updatedValue
      if (scriptStrings) {
        await scriptStrings.reduce(async (acc, current) => {
          await acc

          const scriptFragment = document
            .createRange()
            .createContextualFragment(current)
          const scriptElement = scriptFragment.querySelector('script')

          if (scriptElement.src === '') {
            return Promise.resolve()
          }

          updatedHtmlString = updatedHtmlString.replace(current, '')

          if (
            Array.from(document.querySelectorAll('script')).some(
              (se) => se.src === scriptElement.src
            )
          ) {
            return Promise.resolve()
          }

          return new Promise((resolve) => {
            scriptElement.addEventListener('load', () => {
              resolve()
            })

            document.head.appendChild(scriptElement)
          })
        }, Promise.resolve())
      }

      const fragment = document
        .createRange()
        .createContextualFragment(updatedHtmlString)

      divRef.current.innerHTML = ''
      divRef.current.appendChild(fragment)
    })()
  }, [updatedValue])
  return (
    <div
      ref={divRef}
      dangerouslySetInnerHTML={{
        __html: initialHTMLString,
      }}
      className={className ?? 'entry-content'}
    />
  )
}

/**
 *
 */
export const DefaultImageRenderer = ({ value, alt = '', className }) => {
  const Image = getImage(value)
  return Image ? (
    <GatsbyImage image={Image} alt={alt} className={className} />
  ) : null
}

/**
 *
 */
export const DefaultDateRenderer = ({ value, className }) => {
  const Date = moment(value)
  const Output = Date.format('YYYY.MM.DD')

  return <p className={className}>{Output}</p>
}

export const WidthIframeRender = ({
  src,
  responsiveHeight,
  laptopHeight,
  className,
  width,
  threshold,
}) => {
  const height = width > threshold ? laptopHeight : responsiveHeight
  return (
    <DefaultIframeRenderer src={src} height={height} className={className} />
  )
}

export const DefaultIframeRenderer = ({
  src,
  height,
  className,
  width = '100%',
  frameBorder = '0',
  scrolling = 'no',
}) => (
  <iframe
    src={src}
    height={height}
    className={className}
    width={width}
    frameBorder={frameBorder}
    scrolling={scrolling}
    seamless
  />
)
