import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import * as queryString from 'query-string'
import * as Product from 'modules/product/components/Reference'
import * as Campaign from 'templates/CampaignOverviewTemplate'
import * as Location from 'modules/location/components'
import * as Category from 'modules/category/components/Reference'
import * as Company from 'modules/company/components/Reference'
import * as Design from 'modules/page/design/components/Reference'
import { DESIGN_TYPE_NAME } from 'modules/page/design/consts'
import { getResponsiveHeight } from 'modules/page/design/utils'

import { Link as DefaultLink } from 'atomics/Link'
import * as styles from './styles.module.scss'
import { useWindowWidth } from 'modules/page/width/components/Reference'

/**
 *
 */
const createLink = ({ url, ...props }) => {
  const qs = createQueryString({ ...props })
  return `${url}?${qs}`
}

/**
 *
 */
const createQueryString = ({
  product,
  company,
  length,
  category,
  responsiveData,
  ...props
}) => {
  return queryString.stringify({
    lp_title: product.title,
    maker_name: company.title,
    maker_email: company.acf.mailAddress,
    company_post: `${process.env.MATERIA_PRODUCT_POST_LIST_OWNED_BY_COMPANY}/${category}/${product.wordpress_id}`,
    company_post_height_mobile:
      getResponsiveHeight(responsiveData, DESIGN_TYPE_NAME.MOBILE) * length,
    company_post_height_laptop:
      getResponsiveHeight(responsiveData, DESIGN_TYPE_NAME.LAPTOP) * length,
    post_url: `${process.env.MATERIA_PRODUCT_POST_PAGE}/${product.wordpress_id}`,
    ...props,
  })
}

/**
 *
 */
const ExternalLink = ({ children, ...props }) => <a {...props}>{children}</a>

/**
 *
 */
const ExternalFunction = ({ url, children, additionalQs, ...props }) => {
  const { product, length } = React.useContext(Product.Context)
  const { category } = React.useContext(Category.Context)
  const { company } = React.useContext(Company.Context)
  const { responsiveData } = React.useContext(Design.Context)

  const link = createLink({
    url,
    product,
    length,
    category,
    company,
    responsiveData,
    ...additionalQs,
  })

  return (
    <ExternalLink href={link} {...props}>
      {children}
    </ExternalLink>
  )
}

/**
 * TopPageへのリンク
 */
export const ToTopPage = ({ children = 'TOPへ戻る', ...props }) => (
  <Link to="/" {...props}>
    {children}
  </Link>
)

/**
 * CategoriesPageへのリンク
 */
export const ToCategoriesPage = ({
  children = 'カテゴリ一覧へ',
  className,
  ...props
}) => (
  <Link to="/categories/p/1/" {...props} className={className}>
    {children}
  </Link>
)

/**
 * CompaniesPageへのリンク
 */
export const ToCompaniesPage = ({
  children = '企業一覧ページへ',
  ...props
}) => (
  <Link to="/companies" {...props}>
    {children}
  </Link>
)

/**
 *
 */
export const ToCampaignsPage = ({ children = '特集一覧へ戻る', ...props }) => {
  return (
    <Link to="/campaign" {...props}>
      {children}
    </Link>
  )
}

/**
 *
 */
export const ToCampaignChildrenPage = ({
  children = 'この特集の記事一覧へ戻る',
  ...props
}) => {
  const { pageContext } = React.useContext(Campaign.Context)
  return (
    <Link to={`/campaign/${pageContext.CampaignSlug}/`} {...props}>
      {children}
    </Link>
  )
}

/**
 * 各カテゴリへのリンク
 */
export const ToEachCategoryLink = ({ suffix, children, className }) => {
  const { location } = React.useContext(Location.Context)
  const { category } = React.useContext(Category.Context)

  const path = location.pathname
  const pageNum = path.replace(/[^0-9]/g, '')
  const prefix = path.replace(`p/${pageNum}/`, '')
  return (
    <Link to={`${prefix}${category.slug}${suffix}`} className={className}>
      {children}
    </Link>
  )
}

/**
 * 各企業のリンク
 */
export const ToEachCompanyLink = ({ children, className }) => {
  const { company } = React.useContext(Company.Context)
  return (
    <Link to={`/companies/${company.cSlug}/`} className={className}>
      {children}
    </Link>
  )
}

/**
 * 商品記事のデータです
 */
export const ToProductPage = ({ children, className }) => {
  const { product } = React.useContext(Product.Context)
  return (
    <Link to={`/products/${product.wordpress_id}/`} className={className}>
      {children}
    </Link>
  )
}

/**
 * Iframe用商品記事
 */
export const IframeProductPage = ({ children, className }) => {
  const { product } = React.useContext(Product.Context)
  return (
    <a
      href={`/products/${product.wordpress_id}/`}
      className={className}
      target="_parent"
    >
      {children}
    </a>
  )
}

/**
 * キャンペーンの記事のデータです
 */
export const ToCampaignPage = ({ children }) => {
  const { campaign } = React.useContext(Campaign.Context)
  return <Link to={`/campaign/${campaign.wordpress_id}/`}>{children}</Link>
}

/**
 * 資料請求ボタン
 */
export const DocumentRequestButton = ({ children, className }) => {
  const { width } = useWindowWidth()

  const DefaultText = React.memo(() => {
    return width < 1500 ? (
      <div className={styles.DocumentRequestButton_Text_Container}>
        <div>資料</div>
        <div>ダウンロード</div>
      </div>
    ) : (
      <>資料ダウンロード</>
    )
  }, [width])

  return (
    <DefaultLink className={className} href="#form">
      {children ? children : <DefaultText />}
    </DefaultLink>
  )
}

/**
 * 資料請求・お問い合わせボタン
 */
export const InformationRequestButton = ({ children, className }) => {
  const DefaultText = () => {
    return (
      <div className={styles.InformationRequestButton_Text_Container}>
        <div>資料請求</div>
        <div>お問い合わせ</div>
      </div>
    )
  }

  return (
    <ExternalFunction
      url={process.env.MATERIA_NEXT_APP_INFORMATION_REQUEST_URL}
      className={className}
    >
      {children ? children : <DefaultText />}
    </ExternalFunction>
  )
}

/**
 * プロジェクトに追加ボタン
 */
export const AddProjectButton = ({ children, className }) => {
  const { product } = React.useContext(Product.Context)

  const DefaultText = () => {
    return (
      <div className={styles.AddProjectButton_Text_Container}>
        <div>Arch-LOGで</div>
        <div>製品情報をチェック</div>
      </div>
    )
  }

  if (product.acf.relatedArchProductId) {
    return (
      <ExternalFunction
        url={process.env.MATERIA_NEXT_APP_ADD_PROJECT_URL}
        className={className}
        additionalQs={{ product_id: product.acf.relatedArchProductId }}
      >
        {children ? children : <DefaultText />}
      </ExternalFunction>
    )
  }
  return null
}

/**
 */
export const useVisibleArchLogSearchResultLink = () => {
  const { product } = React.useContext(Product.Context)

  return { visible: product.acf.archLogSearchResultUrl }
}

/**
 *
 */
export const PagerLink = ({ children, suffix }) => {
  const { location } = React.useContext(Location.Context)
  const path = location.pathname
  const PagePath = path.split('/').pop()
  const remakepath = path.replace(`p/${PagePath}`, '')
  return <Link to={`${remakepath}p/${suffix}`}>{children}</Link>
}

/**
 *
 */
export const DownloadFormLink = ({
  children = '資料ダウンロードフォームへ',
  className,
}) => {
  return (
    <a
      href="https://materia.staging.arch-log.com/materia_document_request"
      className={className}
    >
      {children}
    </a>
  )
}

/**
 */
export const ArchLOGPrSiteLink = ({ className, children }) => {
  return (
    <a
      className={className}
      href={process.env.ARCH_LOG_PR_SITE}
      target="_blank"
    >
      {children}
    </a>
  )
}

/**
 */
export const ArchLogSearchLink = ({ className, children }) => {
  const { product } = React.useContext(Product.Context)

  if (!product.acf.archLogSearchResultUrl) {
    return null
  }

  const DefaultText = () =>
    children ? (
      children
    ) : (
      <div className={styles.ArchLogSearchLink_Text_Container}>
        <div>Arch-LOGで</div>
        <div>製品情報をチェック</div>
      </div>
    )

  return (
    <a
      className={className}
      href={product.acf.archLogSearchResultUrl}
      target="_blank"
    >
      <DefaultText />
    </a>
  )
}

/**
 */
export const ArchLogSearchLinkWithPrSite = ({ className }) => {
  const { width } = useWindowWidth()
  const { product } = React.useContext(Product.Context)

  const img = useMemo(() => {
    return width > 1500 ? 'Arch-LOG_up_arrow.svg' : 'Arch_LOG_down_arrow.svg'
  }, [width])

  if (!product.acf.archLogSearchResultUrl) {
    return null
  }

  return width > 1500 ? (
    <>
      <div className={styles.ArchLogSearchLinkWithPrSite_PC}>
        <ArchLogSearchLink className={className} />
        <div className={styles.ArchLOGPrSiteLink_PC}>
          <ArchLOGPrSiteLink>
            <img src={`../../../../../img/${img}`} alt="Arch-LOG" />
          </ArchLOGPrSiteLink>
        </div>
      </div>
    </>
  ) : (
    <div className={styles.ArchLogSearchLinkWithPrSite_Phone}>
      <div className={styles.ArchLOGPrSiteLink_Phone}>
        <ArchLOGPrSiteLink>
          <img src={`../../../../../img/${img}`} alt="Arch-LOG" />
        </ArchLOGPrSiteLink>
      </div>
      <div className={styles.ArchLogSearchLink_Wrapper}>
        <ArchLogSearchLink className={className} />
      </div>
    </div>
  )
}
