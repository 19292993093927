import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ToTopPage } from 'components/shared/Links'
import * as styles from './styles.module.scss'

export const Logo = ({ className }) => (
  <ToTopPage>
    <StaticImage
      src="../../../static/img/Arch-MATERIA.png"
      alt="Arch-Materia"
      objectFit="scale-down"
      layout="constrained"
      backgroundColor="transparent"
      className={className ?? styles.Logo}
      placeholder="none"
      quality="100"
    />
  </ToTopPage>
)

export const TopLogo = ({ className }) => (
  <StaticImage
    src="../../../static/img/top-logo.png"
    alt="Arch-Materia"
    objectFit="scale-down"
    layout="constrained"
    backgroundColor="transparent"
    placeholder="none"
    className={className ?? styles.TopLogo}
    quality="100"
  />
)
