import React, { useEffect, useState } from 'react'
import { Provider } from './Provider'

/**
 *
 */
const isBrowser = typeof window !== 'undefined'

/**
 *
 */
export const Initializer = ({ children }) => {
  const [width, setWidth] = useState(null)

  const updateWidth = () => {
    if (!isBrowser) {
      return
    }

    setWidth(window.innerWidth)
  }

  useEffect(() => {
    updateWidth()
  }, [])

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    window.addEventListener(`resize`, updateWidth, {
      capture: false,
      passive: true,
    })

    return () => window.removeEventListener(`resize`, updateWidth)
  }, [width])

  return <Provider value={{ width }}>{children}</Provider>
}
