import React from 'react'
import { Content as HeaderContent } from './Header'
import { Content as Footer } from 'components/shared/Footer'
import { UpperBottomWavedContainer as BodyWrapper } from 'components/WavedContainer'
import * as PageTitle from 'components/PageTitle'

import * as styles from './styles.module.scss'
import { Helmet } from 'react-helmet'
/**
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
const Body = ({ children }) => (
  <BodyWrapper>
    <div>{children}</div>
  </BodyWrapper>
)

/**
 *
 */
const Header = () => {
  return (
    <div className={styles.Header}>
      <HeaderContent />
    </div>
  )
}

/**
 */
export const Layout = ({ children, metaTitle, pageTitle }) => (
  <Container>
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="非表示のページです" content="noindex" />
    </Helmet>
    <PageTitle.Initializer title={pageTitle}>
      <Header />
      <Body>{children}</Body>
      <Footer />
    </PageTitle.Initializer>
  </Container>
)
