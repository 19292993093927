import React from 'react'

import { Header } from './Header'
import { Footer } from './Footer'
import * as styles from './styles.module.scss'



export const UpperWavedContainer = ({ children }) => (
  <div className={ styles.Container_UpperWaved }>
    <Header/>
    { children }
  </div>
)

export const BottomWavedContainer = ({ children }) => (
  <div className={ styles.Container_BottomWaved }>
    { children }
    <Footer/>
  </div>
)

export const UpperBottomWavedContainer = ({ children }) => (
  <div className={ styles.Container_UpperBottomWaved }>
    <Header/>
    { children }
    <Footer/>
  </div>
)
